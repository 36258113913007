import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Box,
  Collapse,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  IconButton,
  LinearProgress,
  List,
  ListItemButton,
  Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { useForm } from 'react-hook-form';
import { decryptURL } from 'utils/functions';
import { GridContext } from 'contexts/GridContext';
import { ProdutosContext } from 'contexts/ProdutosContext';
import { RelatoriosContext } from 'contexts/RelatoriosContext';
import { ExpedicoesContext } from 'contexts/ExpedicoesContext';
import { useModal } from 'components/Modals';
import DeleteModal from 'components/Modals/DeleteModal';
import Container from 'components/Container';
import InputMask from 'components/InputMask';
import Dropdown from 'components/Dropdown';
import Header from 'components/Header';
import Button from 'components/Button';
import Input from 'components/Input';
import Card from 'components/Card';
import localeText from 'utils/localeText';
import styles from './styles';

const Modal = ({ item, onSubmit }) => {
  const defaultValues = {
    id: item?.id,
    numero: item?.numero || '',
    identificacao: item?.identificacao || '',
  };
  const { closeModal } = useModal();
  const { control, handleSubmit } = useForm({ defaultValues });
  const { postLoading } = useContext(GridContext);

  return (
    <>
      <DialogTitle>{item ? 'Editar' : 'Novo'} Volume</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} />
          <Grid item xs={12} sm={6}>
            <InputMask name="numero" control={control} label="Número" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              name="identificacao"
              control={control}
              label="Identificação"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          loading={postLoading}
          onClick={handleSubmit(onSubmit)}
        >
          Salvar
        </Button>
        <Button variant="contained" color="secondary" onClick={closeModal}>
          Cancelar
        </Button>
      </DialogActions>
    </>
  );
};

const Volume = () => {
  let timer;
  const waitTime = 1000;
  const { hash } = useParams();
  const lotes = decryptURL(hash);
  const produtoRef = useRef(null);
  const refs = useRef({});
  const refx = useRef({});
  const defaultValues = { quantidade: 1, prodemb: '', pedido_id: null };
  const [volumes, setVolumes] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedVolume, setSelectedVolume] = useState(null);
  const [itensVolumes, setItensVolumes] = useState([]);
  const { openModal, closeModal } = useModal();
  const { postGrid, deleteGrid, deleteLoading } = useContext(GridContext);
  const { getURLRelatorio } = useContext(RelatoriosContext);
  const {
    getVolume,
    volumeLoading,
    getVolumes,
    getLoading,
    postVolume,
    postLoading,
  } = useContext(ExpedicoesContext);
  const { control, setValue, watch, handleSubmit } = useForm({ defaultValues });
  const _itensVolumes = itensVolumes?.filter((f) => {
    const pd = watch('pedido_id');
    return Boolean(pd) ? f?.id === pd : true;
  });
  const _volumes = volumes?.filter((f) => {
    const pedido_id = watch('pedido_id');
    if (!Boolean(f?.Itens?.length)) {
      return true;
    } else {
      if (Boolean(pedido_id)) {
        return f?.Itens?.some((s) => s?.pedido_id == pedido_id);
      }
      if (Boolean(lotes?.length)) {
        return f?.Itens?.some((s) =>
          lotes?.some((ss) => s?.lote_id === ss?.id)
        );
      }
    }
    return true;
  });

  const renderItens = ({ data = [], item_id = null }) => {
    const agrupado = data?.reduce((acc, item) => {
      const existingPedido = acc.find((grupo) => grupo.id === item.pedido_id);
      if (existingPedido) {
        existingPedido.itens.push(item);
      } else {
        acc.push({
          id: item?.pedido_id,
          pedido: item?.pedido,
          cliente: item?.cliente,
          destino: item?.destino,
          itens: [item],
        });
      }
      return acc;
    }, []);
    if (agrupado?.length > 0) {
      refs.current = agrupado?.reduce((acc, _, index) => {
        acc[index] = React.createRef();
        return acc;
      }, {});
    }
    setItensVolumes(agrupado);
    setValue('quantidade', 1);
    setValue('prodemb', '');
    if (!agrupado?.some((s) => s?.id === watch('pedido_id'))) {
      setValue('pedido_id', null);
      setSelectedItem(null);
    }
    if (Boolean(item_id)) {
      const index = agrupado
        ?.filter((f) => {
          const pd = watch('pedido_id');
          return Boolean(pd) ? f?.id === pd : true;
        })
        ?.findIndex((item) => item?.itens?.some((s) => s?.id === item_id));
      if (index !== -1 && refs.current[index]) {
        setTimeout(() => {
          setSelectedItem(item_id);
          refs.current[index].current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }, 500);
      }
    }
    setTimeout(() => produtoRef?.current?.focus(), 500);
  };

  const renderVolumes = ({ data = [], volume_id = null }) => {
    setVolumes(data);
    if (data?.length > 0) {
      refx.current = data?.reduce((acc, _, index) => {
        acc[index] = React.createRef();
        return acc;
      }, {});
    }
    if (Boolean(volume_id)) {
      const index = data?.findIndex((item) => item?.id === volume_id);
      if (index !== -1 && refx.current[index]) {
        setTimeout(() => {
          setSelectedVolume(data[index]);
          refx.current[index].current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }, 500);
      }
    }
    setTimeout(() => produtoRef?.current?.focus(), 500);
  };

  const loadData = (props) => {
    const volume_id = props?.volume_id || null;
    const item_id = props?.item_id || null;
    const params = { lotes: lotes?.map((l) => l?.id) };
    getVolume({ params, cb: (data) => renderItens({ data, item_id }) });
    getVolumes({ cb: (data) => renderVolumes({ data, volume_id }) });
    closeModal();
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (Boolean(volumes?.length) && !Boolean(selectedVolume)) {
      setSelectedVolume(volumes[0] || null);
    }
  }, [volumes]);

  useEffect(() => {
    if (!_volumes?.some((s) => s?.id === selectedVolume?.id)) {
      setSelectedVolume(_volumes[0]);
    }
  }, [watch('pedido_id')]);

  const onSubmitItem = (values) => {
    const data = {
      ...values,
      volume_id: selectedVolume?.id,
      lotes: lotes?.map((l) => l?.id),
    };
    postVolume({ data, cb: (res) => loadData({ item_id: res?.id }) });
  };

  const onDeleteItem = (id) =>
    openModal(
      <DeleteModal
        onSubmit={() =>
          deleteGrid({
            params: { id, rotina: 'DocumentoItemVolume' },
            cb: () => loadData(),
          })
        }
      />
    );

  const onSubmitVolume = (data = {}) =>
    postGrid({
      data: { data, rotina: 'Volumes' },
      cb: (res) => loadData({ volume_id: res?.id }),
    });

  const onDeleteVolume = (id) =>
    openModal(
      <DeleteModal
        onSubmit={() =>
          deleteGrid({
            params: { id, rotina: 'Volumes' },
            cb: () => loadData({ volume_id: _volumes?.[0]?.id || null }),
          })
        }
      />
    );

  const onPrintEtiqueta = (id) =>
    getURLRelatorio({
      data: {
        codigo: 'ETQVOL',
        ids: [id],
        impressora_id: 1,
        tipo_impressao: 'PDF',
      },
    });

  return (
    <Container>
      <Header titulo="Volume" />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} display="flex">
          <Card style={styles?.card} title="Volumes">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => onSubmitVolume()}
                >
                  Novo Volume
                </Button>
              </Grid>
              <Grid item xs={12} sx={styles?.scroll}>
                {Boolean(_volumes?.length) ? (
                  <>
                    {getLoading && <LinearProgress />}
                    <List>
                      {(_volumes || [])?.map((e, i) => (
                        <Box key={`${e?.id}`} ref={refx.current[i]}>
                          <ListItemButton
                            disabled={getLoading}
                            divider={i + 1 < _volumes?.length}
                            selected={selectedVolume?.id === e?.id}
                            onClick={() => setSelectedVolume(e)}
                          >
                            <Box flex={1}>
                              <Typography fontWeight="bold">
                                VOLUME {e?.id}
                              </Typography>
                            </Box>
                            <IconButton
                              size="small"
                              onClick={() => onPrintEtiqueta(e?.id)}
                            >
                              <Icon>print</Icon>
                            </IconButton>
                            <IconButton
                              size="small"
                              onClick={() =>
                                openModal(
                                  <Modal item={e} onSubmit={onSubmitVolume} />
                                )
                              }
                            >
                              <Icon>edit</Icon>
                            </IconButton>
                            <IconButton
                              size="small"
                              onClick={() => onDeleteVolume(e?.id)}
                            >
                              <Icon>delete</Icon>
                            </IconButton>
                          </ListItemButton>
                          <Collapse
                            in={
                              Boolean(e?.Itens?.length) &&
                              selectedVolume?.id === e?.id
                            }
                            timeout="auto"
                            unmountOnExit
                          >
                            <Box p={2} sx={styles?.backgroundVol}>
                              <Typography
                                variant="subtitle1"
                                fontWeight="bold"
                                textAlign="start"
                              >
                                Itens
                              </Typography>
                              {e?.Itens?.map((item, i) => (
                                <Box
                                  key={i?.toString()}
                                  display="flex"
                                  flex={1}
                                  alignItems="center"
                                >
                                  <Typography
                                    variant="caption"
                                    textAlign="start"
                                  >
                                    {item?.referencia} - {item?.descricao}
                                  </Typography>
                                  <Box
                                    flex={1}
                                    mx={1}
                                    borderBottom="2px dotted black"
                                  />
                                  <Typography
                                    variant="caption"
                                    textAlign="start"
                                    mr={1}
                                  >
                                    {item?.quantidade || 0}{' '}
                                    {item?.unidade_medida || ''}
                                  </Typography>
                                  <IconButton
                                    size="small"
                                    onClick={() => onDeleteItem(item?.id)}
                                    disabled={deleteLoading}
                                  >
                                    <Icon sx={styles?.deleteIcon}>
                                      delete_forever
                                    </Icon>
                                  </IconButton>
                                </Box>
                              ))}
                            </Box>
                          </Collapse>
                        </Box>
                      ))}
                    </List>
                  </>
                ) : (
                  <Typography variant="caption">
                    Nenhum volume encontrado
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} sm={8} display="flex">
          <Card style={styles?.card} title="Itens">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Dropdown
                  name="pedido_id"
                  control={control}
                  label="Pedido"
                  options={itensVolumes?.map((m) => ({
                    value: m?.id,
                    label: 'Pedido ' + m?.pedido,
                  }))}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Input
                  name="quantidade"
                  control={control}
                  label="Quantidade"
                  type="number"
                  onKeyUp={(e) => {
                    clearTimeout(timer);
                    timer = setTimeout(() => {
                      const value = e?.target?.value;
                      if (Boolean(value)) {
                        produtoRef?.current?.focus();
                      }
                    }, waitTime);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Input
                  inputRef={produtoRef}
                  name="prodemb"
                  control={control}
                  label="Produto"
                  onKeyUp={(e) => {
                    clearTimeout(timer);
                    timer = setTimeout(() => {
                      const value = e?.target?.value;
                      if (Boolean(value)) {
                        handleSubmit(onSubmitItem)();
                        produtoRef?.current?.focus();
                      }
                    }, waitTime);
                  }}
                />
              </Grid>
              <Grid item xs={12} sx={styles?.scroll}>
                {Boolean(_itensVolumes?.length) ? (
                  _itensVolumes?.map((item, idx) => (
                    <Box ref={refs.current[idx]} key={idx?.toString()} mt={2}>
                      <Typography variant="h6" textAlign="start">
                        Pedido {item?.pedido || ''}
                      </Typography>
                      {Boolean(item?.cliente) && (
                        <Typography fontSize={14} textAlign="start">
                          {item?.cliente}
                        </Typography>
                      )}
                      {Boolean(item?.destino) && (
                        <Typography fontSize={14} textAlign="start">
                          {item?.destino}
                        </Typography>
                      )}
                      <DataGrid
                        rows={item?.itens}
                        columns={[
                          {
                            field: 'referencia',
                            headerName: 'Referência',
                            width: 150,
                            sortable: false,
                          },
                          {
                            field: 'descricao',
                            headerName: 'Descrição',
                            flex: 1,
                            sortable: false,
                          },
                          {
                            field: 'quantidade',
                            headerName: 'Quantidade',
                            width: 200,
                            type: 'number',
                            sortable: false,
                            valueGetter: ({ row }) =>
                              `${row?.quantidade_volumada || 0} / ${
                                row?.quantidade || 0
                              } ${row?.um || ''}`,
                          },
                          {
                            field: 'progresso',
                            headerName: 'Progresso',
                            width: 200,
                            type: 'number',
                            sortable: false,
                            renderCell: ({ row }) => {
                              const progress = Math.round(
                                ((row?.quantidade_volumada || 0) /
                                  (row?.quantidade || 0)) *
                                  100
                              );
                              return (
                                <Box
                                  flex={1}
                                  display="flex"
                                  alignItems="center"
                                >
                                  <LinearProgress
                                    variant="determinate"
                                    value={progress}
                                    sx={styles?.linear}
                                    color={
                                      progress < 100 ? 'warning' : 'success'
                                    }
                                  />
                                  <Typography variant="body2">
                                    {progress}%
                                  </Typography>
                                </Box>
                              );
                            },
                          },
                        ]}
                        hideFooter
                        autoHeight
                        density="compact"
                        loading={postLoading || volumeLoading}
                        selectionModel={[selectedItem]}
                        disableColumnMenu
                        showCellRightBorder
                        showColumnRightBorder
                        localeText={localeText}
                        components={{
                          LoadingOverlay: LinearProgress,
                        }}
                      />
                    </Box>
                  ))
                ) : volumeLoading ? (
                  <LinearProgress />
                ) : (
                  <Typography variant="caption">
                    Nenhum item encontrado
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Volume;
