import { createContext, useState } from 'react';
import api from 'services/api';

export const ExpedicoesContext = createContext();

export const ExpedicoesProvider = ({ children }) => {
  const [getLoading, setGetLoading] = useState(false);
  const [putLoading, setPutLoading] = useState(false);
  const [postLoading, setPostLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [lotesLoading, setLotesLoading] = useState(false);
  const [separacaoLoading, setSeparacaoLoading] = useState(false);
  const [embalagemLoading, setEmbalagemLoading] = useState(false);
  const [volumeLoading, setVolumeLoading] = useState(false);

  const getLote = async ({ id, cb = null }) => {
    try {
      setGetLoading(true);
      const { data } = await api.get(`/Suprimentos/Expedicoes/${id}`);
      if (cb) {
        cb(data);
      }
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const getLotes = async ({ params = {}, cb = null }) => {
    try {
      setLotesLoading(true);
      const { data } = await api.get('/Suprimentos/Expedicoes', { params });
      if (cb) {
        cb(data);
      }
    } catch (error) {
    } finally {
      setLotesLoading(false);
    }
  };

  const getSeparacao = async ({ params = {}, cb = null }) => {
    try {
      setSeparacaoLoading(true);
      const { data } = await api.get('/Suprimentos/Expedicoes/Separacao', {
        params,
      });
      if (cb) {
        cb(data);
      }
    } catch (error) {
    } finally {
      setSeparacaoLoading(false);
    }
  };

  const getEmbalagem = async ({ params = {}, cb = null }) => {
    try {
      setEmbalagemLoading(true);
      const { data } = await api.get('/Suprimentos/Expedicoes/Embalagem', {
        params,
      });
      if (cb) {
        cb(data);
      }
    } catch (error) {
    } finally {
      setEmbalagemLoading(false);
    }
  };

  const getEmbalagens = async ({ params, cb = null }) => {
    try {
      setGetLoading(true);
      const { data } = await api.get('/Suprimentos/Expedicoes/Embalagens', {
        params,
      });
      if (cb) {
        cb(data);
      }
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const getVolume = async ({ params = {}, cb = null }) => {
    try {
      setVolumeLoading(true);
      const { data } = await api.get('/Suprimentos/Expedicoes/Volume', {
        params,
      });
      if (cb) {
        cb(data);
      }
    } catch (error) {
    } finally {
      setVolumeLoading(false);
    }
  };

  const getVolumes = async ({ params, cb = null }) => {
    try {
      setGetLoading(true);
      const { data } = await api.get('/Suprimentos/Expedicoes/Volumes', {
        params,
      });
      if (cb) {
        cb(data);
      }
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const postSeparacao = async ({ data, cb = null }) => {
    try {
      setPostLoading(true);
      const res = await api.post('/Suprimentos/Expedicoes/Separacao', data);
      if (cb) {
        cb(res?.data);
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const postEmbalagem = async ({ data, cb = null }) => {
    try {
      setPostLoading(true);
      const res = await api.post('/Suprimentos/Expedicoes/Embalagem', data);
      if (cb) {
        cb(res?.data);
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const postVolume = async ({ data, cb = null }) => {
    try {
      setPostLoading(true);
      await api.post('/Suprimentos/Expedicoes/Volume', data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const postFaturamento = async ({ data, cb = null }) => {
    try {
      setPostLoading(true);
      await api.post('/Suprimentos/Expedicoes/Faturamento', data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const postCarregamento = async ({ data, cb = null }) => {
    try {
      setPostLoading(true);
      await api.post('/Suprimentos/Expedicoes/Carregamento', data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const postLiberacao = async ({ data, cb = null }) => {
    try {
      setPostLoading(true);
      await api.post('/Suprimentos/Expedicoes/Liberacao', data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const deleteItensLote = async ({ data, cb }) => {
    try {
      setDeleteLoading(true);
      await api.post('/Suprimentos/Expedicoes/DeleteItens', data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setDeleteLoading(false);
    }
  };

  const putItemLote = async ({ data, cb }) => {
    try {
      setPutLoading(true);
      await api.put('/Suprimentos/Expedicoes/Itens', data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPutLoading(false);
    }
  };

  return (
    <ExpedicoesContext.Provider
      value={{
        getLoading,
        putLoading,
        postLoading,
        deleteLoading,
        lotesLoading,
        separacaoLoading,
        embalagemLoading,
        volumeLoading,
        getLote,
        getLotes,
        getSeparacao,
        getEmbalagem,
        getEmbalagens,
        getVolumes,
        getVolume,
        postVolume,
        postFaturamento,
        postSeparacao,
        postEmbalagem,
        postLiberacao,
        postCarregamento,
        deleteItensLote,
        putItemLote,
      }}
    >
      {children}
    </ExpedicoesContext.Provider>
  );
};
