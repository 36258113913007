import React from 'react';
import { Box, Paper, Tooltip, Typography } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import styles from './styles';

const Line = ({ value, label, tooltip }) => (
  <Box display="flex" alignItems="center">
    <Typography variant="caption" fontWeight="bold" flex={2}>
      {label}:
    </Typography>
    <Box display="flex" alignItems="center" flex={3}>
      <Typography variant="caption" flex={1}>
        {value || '-'}
      </Typography>
      {Boolean(tooltip) && (
        <Tooltip title={tooltip} placement="right">
          <InfoOutlined color="primary" fontSize="small" />
        </Tooltip>
      )}
    </Box>
  </Box>
);

const Itens = ({ itens = [], attribute = '' }) => {
  let parseItens = [];

  itens?.map((item) => {
    if (attribute === 'quantidade_volumada' && Boolean(item?.embalagem_id)) {
      const find = parseItens?.findIndex(
        (i) => i?.embalagem_id === item?.embalagem_id
      );
      if (find >= 0) {
        parseItens?.[find]?.itens?.push(item);
      } else {
        parseItens?.push({
          embalagem_id: item?.embalagem_id,
          itens: [item],
        });
      }
    } else {
      parseItens?.push(item);
    }
  });

  return parseItens?.map((item, idx) => {
    const qt_restante = (item?.quantidade || 0) - (item?.[attribute] || 0);
    const str_qtd = `${qt_restante} ${item?.um || ''}`;
    const tip_arr = [
      {
        key: 'quantidade_separada',
        label: 'Separado',
        value: item?.quantidade_separada,
      },
      {
        key: 'quantidade_embalada',
        label: 'Embalado',
        value: item?.quantidade_embalada,
      },
      {
        key: 'quantidade_volumada',
        label: 'Volume',
        value: item?.quantidade_volumada,
      },
      {
        key: 'quantidade_carregada',
        label: 'Carregado',
        value: item?.quantidade_carregada,
      },
    ];
    const tip_text = (
      <span style={styles?.tooltip}>
        Quantidades:
        <br />
        <br />
        <br />
        Pedido: {item?.quantidade || 0} {item?.um || ''}
        {tip_arr
          ?.filter((attr) => attribute === attr.key)
          ?.map((attr) => (
            <React.Fragment key={attr.key}>
              <br />
              <br />
              {attr.label}: {attr.value || 0} {item?.um || ''}
            </React.Fragment>
          ))}
        <br />
        <br />
        Restante: {qt_restante} {item?.um || ''}
      </span>
    );

    if (Boolean(item?.embalagem_id) && Boolean(item?.itens?.length)) {
      return (
        <Paper key={idx} sx={styles?.embPaper} elevation={1}>
          <Typography variant="body1" fontWeight="bold">
            Embalagem {item?.embalagem_id || ''}
          </Typography>
          {item?.itens?.map((x, index) => (
            <Box
              key={index?.toString()}
              display="flex"
              flex={1}
              alignItems="center"
            >
              <Typography
                maxWidth={150}
                variant="caption"
                textAlign="start"
                overflow="hidden"
                whiteSpace="nowrap"
                textOverflow="ellipsis"
              >
                {x?.referencia} - {x?.descricao}
              </Typography>
              <Box flex={1} mx={1} borderBottom="2px dotted black" />
              <Typography variant="caption" textAlign="start" mr={1}>
                {x?.quantidade_embalada || 0} {x?.um || ''}
              </Typography>
            </Box>
          ))}
        </Paper>
      );
    }

    return (
      <Paper key={idx} sx={styles?.itemPaper} elevation={1}>
        <Typography variant="body1" fontWeight="bold">
          {item?.descricao}
        </Typography>
        <Line label="Referência" value={item?.referencia} />
        <Line label="Quantidade" value={str_qtd} tooltip={tip_text} />
        <Line label="Nº do Lote" value={item?.lote} />
        <Line label="Nº do Pedido" value={item?.pedido} />
      </Paper>
    );
  });
};

export default Itens;
