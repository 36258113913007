import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Checkbox,
  Chip,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  IconButton,
  InputAdornment,
  List,
  ListItemButton,
  Typography,
} from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { TrendingDown, TrendingUp } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { status } from 'utils/functions';
import { ExpedicoesContext } from 'contexts/ExpedicoesContext';
import { useModal, useDialog } from 'components/Modals';
import EtiquetaModal from 'components/Modals/EtiquetaModal';
import DeleteModal from 'components/Modals/DeleteModal';
import Container from 'components/Container';
import InputMask from 'components/InputMask';
import Button from 'components/Button';
import Loader from 'components/Loader';
import Header from 'components/Header';
import Card from 'components/Card';
import localeText from 'utils/localeText';
import styles from './styles';
import moment from 'moment';

const Line = ({ label, value, visible = true, large = false }) =>
  visible && (
    <Box display="flex" alignItems="center" flex={1}>
      <Typography
        variant="caption"
        fontWeight="bold"
        flex={2}
        textAlign="start"
      >
        {label}:
      </Typography>
      <Typography variant="caption" flex={large ? 6 : 3} textAlign="start">
        {value || '-'}
      </Typography>
    </Box>
  );

const Modal = ({ item, onSubmit }) => {
  const defaultValues = { id: item?.id, quantidade: item?.quantidade || 0 };
  const { closeModal } = useModal();
  const { control, handleSubmit } = useForm({ defaultValues });
  const { putLoading } = useContext(ExpedicoesContext);

  return (
    <>
      <DialogTitle>Editar {item?.descricao}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Line large label="Referência" value={item?.referencia} />
            <Line
              large
              label="Prazo de Entrega"
              value={
                Boolean(item?.przent) &&
                moment(item?.przent)?.format('DD/MM/YYYY')
              }
            />
            <Line
              large
              label="Saldo"
              value={`${item?.saldo || 0} ${item?.unidade_medida || ''}`}
            />
          </Grid>
          <Grid item xs={12}>
            <InputMask
              name="quantidade"
              control={control}
              label="Quantidade"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {item?.unidade_medida || ''}
                  </InputAdornment>
                ),
              }}
            />
            <Typography variant="caption" color="secondary">
              Máximo permitido:{' '}
              {(item?.quantidade_pedido || 0) - (item?.quantidade_gerada || 0)}{' '}
              {item?.unidade_medida || ''}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          loading={putLoading}
          onClick={handleSubmit(onSubmit)}
        >
          Salvar
        </Button>
        <Button variant="contained" color="secondary" onClick={closeModal}>
          Cancelar
        </Button>
      </DialogActions>
    </>
  );
};

const ConfirmModal = ({ onSubmit }) => {
  return (
    <DialogContent>
      <Box display="flex" flexDirection="column" alignItems="center" gap={5}>
        <Icon sx={{ fontSize: 150, color: 'primary.main' }}>request_quote</Icon>
        <Typography variant="h6">Selecione o tipo de faturamento</Typography>
        <Box display="flex" alignSelf="stretch" justifyContent="space-evenly">
          <Button
            variant="outlined"
            color="primary"
            onClick={() => onSubmit(10)}
          >
            Nota Fiscal
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => onSubmit(25)}
          >
            Fatura
          </Button>
        </Box>
      </Box>
    </DialogContent>
  );
};

const Detalhes = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [lote, setLote] = useState(null);
  const [checked, setChecked] = useState([]);
  const [selected, setSelected] = useState(null);
  const [itemSelected, setItemSelected] = useState([]);
  const { openModal, closeModal } = useModal();
  const { openDialog, closeDialog } = useDialog();
  const {
    getLoading,
    getLote,
    deleteItensLote,
    deleteLoading,
    putItemLote,
    putLoading,
    postFaturamento,
    postLoading,
  } = useContext(ExpedicoesContext);

  const loadData = () => {
    const cb = (data) => {
      if (Boolean(data)) {
        setLote(() => {
          setSelected(data?.Pedidos?.[0] || null);
          return data;
        });
      } else {
        navigate(-1);
      }
    };
    closeModal();
    closeDialog();
    getLote({ id, cb });
  };

  const handleCheck = (pedido) => {
    if (checked?.some((s) => s?.id === pedido?.id)) {
      setChecked((prev) => prev?.filter((f) => f?.id !== pedido?.id));
    } else {
      setChecked((prev) => [...prev, pedido]);
    }
  };

  const handleFaturamento = (especie_id) => {
    const pedidos = (
      Boolean(checked?.length)
        ? checked || []
        : lote?.Pedidos?.filter((f) => !Boolean(f?.hasDestino)) || []
    )?.map((p) => ({
      id: p?.id,
      Itens: p?.Itens?.map((i) => ({
        id: i?.idped,
        quantidade: i?.quantidade,
      })),
    }));

    const data = { lote_id: id, pedidos, especie_id };

    postFaturamento({ data, cb: loadData });
  };

  const onDelete = (data) => deleteItensLote({ data, cb: loadData });

  const onEdit = (data) => putItemLote({ data, cb: loadData });

  useEffect(loadData, []);

  if (getLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <Header titulo={`Lote ${lote?.documento || ''}`} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {Boolean(lote?.dtemissao) && (
            <Typography variant="body2">
              Emitido em{' '}
              {moment(lote?.dtemissao)?.format('DD/MM/YYYY [às] HH:mm')}
            </Typography>
          )}
          {Boolean(lote?.przent) && (
            <Typography variant="body2">
              Entrega em {moment(lote?.przent)?.format('DD/MM/YYYY')}
            </Typography>
          )}
          <Chip
            label={status[lote?.status]?.tooltip}
            variant="outlined"
            color={
              status[lote?.status]?.icon === 'priority_high'
                ? 'error'
                : 'warning'
            }
            icon={<Icon>{status[lote?.status]?.icon}</Icon>}
            sx={styles?.modalChip}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} display="flex">
          <Card title="Pedidos" style={styles?.card}>
            <Button
              color="primary"
              variant="contained"
              loading={postLoading}
              startIcon={<Icon>attach_money</Icon>}
              sx={styles?.btnft}
              onClick={() =>
                openModal(<ConfirmModal onSubmit={handleFaturamento} />)
              }
            >
              Faturar Pedido(s)
            </Button>
            {Boolean(lote?.Pedidos?.length) ? (
              <List>
                {(lote?.Pedidos || [])?.map((p, i) => (
                  <Box key={`${p?.id}`} display="flex" alignItems="center">
                    <Checkbox
                      size="small"
                      disableRipple
                      sx={styles?.checkbox}
                      disabled={Boolean(p?.hasDestino)}
                      checked={checked?.some((s) => s?.id === p?.id)}
                      onChange={() => handleCheck(p)}
                    />
                    <ListItemButton
                      divider={i + 1 < lote?.Pedidos?.length}
                      selected={selected?.id === p?.id}
                      onClick={() => setSelected(p)}
                    >
                      <Box flex={1}>
                        <Line label="Número" value={p?.documento} />
                        <Line label="Cliente" value={p?.cadastro} />
                        <Line label="Destino" value={p?.destino} />
                        <Line
                          label="Data de Emissão"
                          value={
                            Boolean(p?.dtemissao) &&
                            moment(p?.dtemissao)?.format(
                              'DD/MM/YYYY [às] HH:mm'
                            )
                          }
                        />
                        <Line
                          label="Prazo de Entrega"
                          value={
                            Boolean(p?.przent) &&
                            moment(p?.przent)?.format('DD/MM/YYYY')
                          }
                        />
                      </Box>
                      <IconButton
                        size="small"
                        onClick={() =>
                          openModal(
                            <DeleteModal
                              onSubmit={() =>
                                onDelete(p?.Itens?.map((m) => m?.id))
                              }
                            />
                          )
                        }
                      >
                        <Icon>delete</Icon>
                      </IconButton>
                    </ListItemButton>
                  </Box>
                ))}
              </List>
            ) : (
              <Typography variant="caption">
                Nenhum pedido encontrado
              </Typography>
            )}
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={8} display="flex">
          <Card title="Itens" style={styles?.card}>
            <Button
              color="primary"
              variant="outlined"
              startIcon={<Icon>print</Icon>}
              sx={styles?.btnft}
              onClick={() => {
                const itens = itemSelected?.length
                  ? selected?.Itens?.filter((f) =>
                      itemSelected?.includes(f?.id)
                    )
                  : selected?.Itens || [];
                return openDialog(
                  <EtiquetaModal itens={itens} />,
                  'Imprimir Etiquetas'
                );
              }}
            >
              Imprimir Etiquetas
            </Button>
            <DataGrid
              rows={selected?.Itens || []}
              columns={[
                {
                  field: 'descricao',
                  headerName: 'Descrição',
                  width: 400,
                  sortable: false,
                },
                {
                  field: 'referencia',
                  headerName: 'Referência',
                  width: 150,
                  sortable: false,
                },
                {
                  field: 'przent',
                  headerName: 'Prazo de Entrega',
                  width: 150,
                  sortable: false,
                  valueFormatter: ({ value }) =>
                    Boolean(value) ? moment(value)?.format('DD/MM/YYYY') : '',
                },
                {
                  field: 'saldo',
                  headerName: 'Saldo',
                  width: 150,
                  sortable: false,
                  renderCell: ({ row }) => {
                    const qtd = (row?.saldo || 0) - (row?.quantidade || 0);
                    return (
                      <Chip
                        size="small"
                        variant="outlined"
                        icon={qtd >= 0 ? <TrendingUp /> : <TrendingDown />}
                        color={qtd >= 0 ? 'success' : 'error'}
                        label={`${row?.saldo || 0} ${
                          row?.unidade_medida || ''
                        }`}
                      />
                    );
                  },
                },
                {
                  field: 'quantidade',
                  headerName: 'Quantidade',
                  width: 200,
                  sortable: false,
                  renderCell: ({ row }) => (
                    <Box flex={1}>
                      <Line
                        label="Pedido"
                        visible={row?.hasOwnProperty('quantidade_pedido')}
                        value={`${row?.quantidade_pedido || 0} ${
                          row?.unidade_medida || ''
                        }`}
                      />
                      <Line
                        label="Lote"
                        visible={row?.hasOwnProperty('quantidade')}
                        value={`${row?.quantidade || 0} ${
                          row?.unidade_medida || ''
                        }`}
                      />
                      <Line
                        label="Separado"
                        visible={row?.hasOwnProperty('quantidade_separada')}
                        value={`${row?.quantidade_separada || 0} ${
                          row?.unidade_medida || ''
                        }`}
                      />
                      <Line
                        label="Embalado"
                        visible={row?.hasOwnProperty('quantidade_embalada')}
                        value={`${row?.quantidade_embalada || 0} ${
                          row?.unidade_medida || ''
                        }`}
                      />
                      <Line
                        label="Volume"
                        visible={row?.hasOwnProperty('quantidade_volumada')}
                        value={`${row?.quantidade_volumada || 0} ${
                          row?.unidade_medida || ''
                        }`}
                      />
                    </Box>
                  ),
                },
                {
                  field: 'actions',
                  headerName: 'Ações',
                  type: 'actions',
                  width: 100,
                  getActions: ({ row }) => [
                    <GridActionsCellItem
                      icon={<Icon>edit</Icon>}
                      label="Editar"
                      onClick={() =>
                        openModal(<Modal item={row} onSubmit={onEdit} />)
                      }
                    />,
                    <GridActionsCellItem
                      icon={<Icon>delete</Icon>}
                      label="Excluir"
                      onClick={() =>
                        openModal(
                          <DeleteModal onSubmit={() => onDelete([row?.id])} />
                        )
                      }
                    />,
                  ],
                },
              ]}
              hideFooter
              autoHeight
              density="compact"
              disableSelectionOnClick
              disableColumnMenu
              checkboxSelection
              showCellRightBorder
              showColumnRightBorder
              getRowHeight={() => 'auto'}
              localeText={localeText}
              selectionModel={itemSelected}
              onSelectionModelChange={(ids) => setItemSelected(ids)}
              loading={getLoading || deleteLoading || putLoading}
            />
          </Card>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center">
          <Button
            color="secondary"
            variant="contained"
            loading={deleteLoading}
            startIcon={<Icon>delete</Icon>}
            onClick={() =>
              openModal(
                <DeleteModal
                  onSubmit={() =>
                    onDelete(
                      (lote?.Pedidos || [])?.flatMap((p) =>
                        p?.Itens?.map((pi) => pi?.id)
                      )
                    )
                  }
                />
              )
            }
          >
            Excluir Lote
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Detalhes;
