import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Box,
  Collapse,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  IconButton,
  LinearProgress,
  List,
  ListItemButton,
  Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { useForm } from 'react-hook-form';
import { decryptURL } from 'utils/functions';
import { GridContext } from 'contexts/GridContext';
import { DropsContext } from 'contexts/DropsContext';
import { RelatoriosContext } from 'contexts/RelatoriosContext';
import { ExpedicoesContext } from 'contexts/ExpedicoesContext';
import { useModal, useDialog } from 'components/Modals';
import EtiquetaModal from 'components/Modals/EtiquetaModal';
import DeleteModal from 'components/Modals/DeleteModal';
import VirtualDrop from 'components/VirtualDrop';
import Container from 'components/Container';
import InputMask from 'components/InputMask';
import Dropdown from 'components/Dropdown';
import Header from 'components/Header';
import Button from 'components/Button';
import Input from 'components/Input';
import Card from 'components/Card';
import localeText from 'utils/localeText';
import styles from './styles';

const Modal = ({ item, onSubmit }) => {
  const defaultValues = {
    id: item?.id,
    numero: item?.numero || '',
    identificacao: item?.identificacao || '',
    produto_id: item?.produto_id || null,
  };
  const { closeModal } = useModal();
  const { control, handleSubmit } = useForm({ defaultValues });
  const { postLoading } = useContext(GridContext);
  const { drops } = useContext(DropsContext);

  return (
    <>
      <DialogTitle>{item ? 'Editar' : 'Nova'} Embalagem</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} />
          <Grid item xs={12} sm={6}>
            <InputMask name="numero" control={control} label="Número" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              name="identificacao"
              control={control}
              label="Identificação"
            />
          </Grid>
          <Grid item xs={12}>
            <VirtualDrop
              name="produto_id"
              control={control}
              label="Tipo da Embalagem"
              options={drops?.Produto}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          loading={postLoading}
          onClick={handleSubmit(onSubmit)}
        >
          Salvar
        </Button>
        <Button variant="contained" color="secondary" onClick={closeModal}>
          Cancelar
        </Button>
      </DialogActions>
    </>
  );
};

const Embalagem = () => {
  let timer;
  const waitTime = 1000;
  const { hash } = useParams();
  const lotes = decryptURL(hash);
  const produtoRef = useRef(null);
  const refs = useRef({});
  const refx = useRef({});
  const defaultValues = { quantidade: 1, produto_id: '', pedido_id: null };
  const [embalagens, setEmbalagens] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedEmbalagem, setSelectedEmbalagem] = useState(null);
  const [itensEmbalagens, setItensEmbalagens] = useState([]);
  const { openDialog } = useDialog();
  const { openModal, closeModal } = useModal();
  const { postGrid, deleteGrid, deleteLoading } = useContext(GridContext);
  const { getURLRelatorio } = useContext(RelatoriosContext);
  const {
    getEmbalagem,
    embalagemLoading,
    getEmbalagens,
    getLoading,
    postEmbalagem,
    postLoading,
  } = useContext(ExpedicoesContext);
  const { control, setValue, watch, handleSubmit } = useForm({ defaultValues });
  const _itensEmbalagens = itensEmbalagens?.filter((f) => {
    const pd = watch('pedido_id');
    return Boolean(pd) ? f?.id === pd : true;
  });
  const _embalagens = embalagens?.filter((f) => {
    const pedido_id = watch('pedido_id');
    if (!Boolean(f?.Itens?.length)) {
      return true;
    } else {
      if (Boolean(pedido_id)) {
        return f?.Itens?.some((s) => s?.pedido_id == pedido_id);
      }
      if (Boolean(lotes?.length)) {
        return f?.Itens?.some((s) =>
          lotes?.some((ss) => s?.lote_id === ss?.id)
        );
      }
    }
    return true;
  });

  const renderItens = ({ data = [], item_id = null }) => {
    const agrupado = data?.reduce((acc, item) => {
      const existingPedido = acc.find((grupo) => grupo.id === item.pedido_id);

      if (existingPedido) {
        existingPedido.itens.push(item);
      } else {
        acc.push({
          id: item?.pedido_id,
          pedido: item?.pedido,
          cliente: item?.cliente,
          destino: item?.destino,
          itens: [item],
        });
      }

      return acc;
    }, []);

    if (agrupado?.length > 0) {
      refs.current = agrupado?.reduce((acc, _, index) => {
        acc[index] = React.createRef();
        return acc;
      }, {});
    }

    setItensEmbalagens(agrupado);
    setValue('quantidade', 1);
    setValue('produto_id', '');
    if (!agrupado?.some((s) => s?.id === watch('pedido_id'))) {
      setValue('pedido_id', null);
      setSelectedItem(null);
    }

    if (Boolean(item_id)) {
      const index = agrupado
        ?.filter((f) => {
          const pd = watch('pedido_id');
          return Boolean(pd) ? f?.id === pd : true;
        })
        ?.findIndex((item) => item?.itens?.some((s) => s?.id === item_id));

      if (index !== -1 && refs.current[index]) {
        setTimeout(() => {
          setSelectedItem(item_id);
          if (Boolean(refs.current[index].current)) {
            refs.current[index].current.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            });
          }
        }, 500);
      }
    }

    setTimeout(() => produtoRef?.current?.focus(), 500);
  };

  const renderEmbalagens = ({ data = [], embalagem_id = null }) => {
    setEmbalagens(data);

    if (data?.length > 0) {
      refx.current = data?.reduce((acc, _, index) => {
        acc[index] = React.createRef();
        return acc;
      }, {});
    }

    if (Boolean(embalagem_id)) {
      const index = data?.findIndex((item) => item?.id === embalagem_id);

      if (index !== -1 && refx.current[index]) {
        setTimeout(() => {
          setSelectedEmbalagem(data[index]);
          if (Boolean(refx.current[index].current)) {
            refx.current[index].current.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            });
          }
        }, 500);
      }
    }

    setTimeout(() => produtoRef?.current?.focus(), 500);
  };

  const loadData = (props) => {
    const embalagem_id = props?.embalagem_id || null;
    const item_id = props?.item_id || null;
    const params = { lotes: lotes?.map((l) => l?.id) };
    getEmbalagem({ params, cb: (data) => renderItens({ data, item_id }) });
    getEmbalagens({ cb: (data) => renderEmbalagens({ data, embalagem_id }) });
    closeModal();
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (Boolean(embalagens?.length) && !Boolean(selectedEmbalagem)) {
      setSelectedEmbalagem(embalagens[0] || null);
    }
  }, [embalagens]);

  useEffect(() => {
    if (!_embalagens?.some((s) => s?.id === selectedEmbalagem?.id)) {
      setSelectedEmbalagem(_embalagens[0]);
    }
  }, [watch('pedido_id')]);

  const onSubmitItem = (values) => {
    if (values?.produto_id?.startsWith('EMB')) {
      onSubmitEmbalagem({
        produto_id: values?.produto_id?.replace(/EMB/g, ''),
      });
    } else {
      const data = {
        ...values,
        embalagem_id: selectedEmbalagem?.id,
        lotes: lotes?.map((l) => l?.id),
      };
      postEmbalagem({ data, cb: (res) => loadData({ item_id: res?.id }) });
    }
  };

  const onDeleteItem = (id) =>
    openModal(
      <DeleteModal
        onSubmit={() =>
          deleteGrid({
            params: { id, rotina: 'DocumentoItemEmbalagem' },
            cb: () => loadData(),
          })
        }
      />
    );

  const onSubmitEmbalagem = (data = {}) =>
    postGrid({
      data: { data, rotina: 'Embalagem' },
      cb: (res) => loadData({ embalagem_id: res?.id }),
    });

  const onDeleteEmbalagem = (id) =>
    openModal(
      <DeleteModal
        onSubmit={() =>
          deleteGrid({
            params: { id, rotina: 'Embalagem' },
            cb: () => loadData({ embalagem_id: _embalagens?.[0]?.id || null }),
          })
        }
      />
    );

  const onPrintEtiqueta = (id) =>
    getURLRelatorio({
      data: {
        codigo: 'ETQEMB',
        ids: [id],
        impressora_id: 1,
        tipo_impressao: 'PDF',
      },
    });

  return (
    <Container>
      <Header titulo="Embalagem" />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} display="flex">
          <Card style={styles?.card} title="Embalagens">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => onSubmitEmbalagem()}
                >
                  Nova Embalagem
                </Button>
              </Grid>
              <Grid item xs={12} sx={styles?.scroll}>
                {Boolean(_embalagens?.length) ? (
                  <>
                    {getLoading && <LinearProgress />}
                    <List>
                      {(_embalagens || [])?.map((e, i) => (
                        <Box key={`${e?.id}`} ref={refx.current[i]}>
                          <ListItemButton
                            disabled={getLoading}
                            divider={i + 1 < _embalagens?.length}
                            selected={selectedEmbalagem?.id === e?.id}
                            onClick={() => setSelectedEmbalagem(e)}
                          >
                            <Box flex={1}>
                              <Typography fontWeight="bold">
                                EMBALAGEM {e?.id}
                              </Typography>
                              {Boolean(e?.tipo) && (
                                <Typography variant="caption">
                                  {e?.tipo}
                                </Typography>
                              )}
                            </Box>
                            <IconButton
                              size="small"
                              onClick={() => onPrintEtiqueta(e?.id)}
                            >
                              <Icon>print</Icon>
                            </IconButton>
                            <IconButton
                              size="small"
                              onClick={() =>
                                openModal(
                                  <Modal
                                    item={e}
                                    onSubmit={onSubmitEmbalagem}
                                  />
                                )
                              }
                            >
                              <Icon>edit</Icon>
                            </IconButton>
                            <IconButton
                              size="small"
                              onClick={() => onDeleteEmbalagem(e?.id)}
                            >
                              <Icon>delete</Icon>
                            </IconButton>
                          </ListItemButton>
                          <Collapse
                            in={
                              Boolean(e?.Itens?.length) &&
                              selectedEmbalagem?.id === e?.id
                            }
                            timeout="auto"
                            unmountOnExit
                          >
                            <Box p={2} sx={styles?.backgroundEmb}>
                              <Typography
                                variant="subtitle1"
                                fontWeight="bold"
                                textAlign="start"
                              >
                                Itens
                              </Typography>
                              {e?.Itens?.map((item, i) => (
                                <Box
                                  key={i?.toString()}
                                  display="flex"
                                  flex={1}
                                  alignItems="center"
                                >
                                  <Typography
                                    variant="caption"
                                    textAlign="start"
                                  >
                                    {item?.referencia} - {item?.descricao}
                                  </Typography>
                                  <Box
                                    flex={1}
                                    mx={1}
                                    borderBottom="2px dotted black"
                                  />
                                  <Typography
                                    variant="caption"
                                    textAlign="start"
                                    mr={1}
                                  >
                                    {item?.quantidade || 0}{' '}
                                    {item?.unidade_medida || ''}
                                  </Typography>
                                  <IconButton
                                    size="small"
                                    onClick={() => onDeleteItem(item?.id)}
                                    disabled={deleteLoading}
                                  >
                                    <Icon sx={styles?.deleteIcon}>
                                      delete_forever
                                    </Icon>
                                  </IconButton>
                                </Box>
                              ))}
                            </Box>
                          </Collapse>
                        </Box>
                      ))}
                    </List>
                  </>
                ) : (
                  <Typography variant="caption">
                    Nenhuma embalagem encontrada
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} sm={8} display="flex">
          <Card style={styles?.card} title="Itens">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Dropdown
                  name="pedido_id"
                  control={control}
                  label="Pedido"
                  options={itensEmbalagens?.map((m) => ({
                    value: m?.id,
                    label: 'Pedido ' + m?.pedido,
                  }))}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Input
                  name="quantidade"
                  control={control}
                  label="Quantidade"
                  type="number"
                  onKeyUp={(e) => {
                    clearTimeout(timer);
                    timer = setTimeout(() => {
                      const value = e?.target?.value;
                      if (Boolean(value)) {
                        produtoRef?.current?.focus();
                      }
                    }, waitTime);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Input
                  inputRef={produtoRef}
                  name="produto_id"
                  control={control}
                  label="Produto"
                  onKeyUp={(e) => {
                    clearTimeout(timer);
                    timer = setTimeout(() => {
                      const value = e?.target?.value;
                      if (Boolean(value)) {
                        handleSubmit(onSubmitItem)();
                        produtoRef?.current?.focus();
                      }
                    }, waitTime);
                  }}
                />
              </Grid>
              <Grid item xs={12} sx={styles?.scroll}>
                {Boolean(_itensEmbalagens?.length) ? (
                  _itensEmbalagens?.map((item, idx) => (
                    <Box ref={refs.current[idx]} key={idx?.toString()} mt={2}>
                      <Box display="flex" alignItems="center">
                        <Box flex={1}>
                          <Typography variant="h6" textAlign="start">
                            Pedido {item?.pedido || ''}
                          </Typography>
                          {Boolean(item?.cliente) && (
                            <Typography fontSize={14} textAlign="start">
                              {item?.cliente}
                            </Typography>
                          )}
                          {Boolean(item?.destino) && (
                            <Typography fontSize={14} textAlign="start">
                              {item?.destino}
                            </Typography>
                          )}
                        </Box>
                        <Button
                          variant="outlined"
                          color="primary"
                          startIcon={<Icon>print</Icon>}
                          onClick={() =>
                            openDialog(
                              <EtiquetaModal itens={item?.itens} />,
                              'Imprimir Etiquetas'
                            )
                          }
                        >
                          Imprimir Etiquetas
                        </Button>
                      </Box>
                      <DataGrid
                        rows={item?.itens}
                        columns={[
                          {
                            field: 'referencia',
                            headerName: 'Referência',
                            width: 150,
                            sortable: false,
                          },
                          {
                            field: 'descricao',
                            headerName: 'Descrição',
                            flex: 1,
                            sortable: false,
                          },
                          {
                            field: 'quantidade',
                            headerName: 'Quantidade',
                            width: 200,
                            type: 'number',
                            sortable: false,
                            valueGetter: ({ row }) =>
                              `${row?.quantidade_embalada || 0} / ${
                                row?.quantidade || 0
                              } ${row?.um || ''}`,
                          },
                          {
                            field: 'progresso',
                            headerName: 'Progresso',
                            width: 200,
                            type: 'number',
                            sortable: false,
                            renderCell: ({ row }) => {
                              const progress = Math.round(
                                ((row?.quantidade_embalada || 0) /
                                  (row?.quantidade || 0)) *
                                  100
                              );
                              return (
                                <Box
                                  flex={1}
                                  display="flex"
                                  alignItems="center"
                                >
                                  <LinearProgress
                                    variant="determinate"
                                    value={progress}
                                    sx={styles?.linear}
                                    color={
                                      progress < 100 ? 'warning' : 'success'
                                    }
                                  />
                                  <Typography variant="body2">
                                    {progress}%
                                  </Typography>
                                </Box>
                              );
                            },
                          },
                        ]}
                        hideFooter
                        autoHeight
                        density="compact"
                        loading={postLoading || embalagemLoading}
                        selectionModel={[selectedItem]}
                        disableColumnMenu
                        showCellRightBorder
                        showColumnRightBorder
                        localeText={localeText}
                        components={{
                          LoadingOverlay: LinearProgress,
                        }}
                      />
                    </Box>
                  ))
                ) : embalagemLoading ? (
                  <LinearProgress />
                ) : (
                  <Typography variant="caption">
                    Nenhum item encontrado
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Embalagem;
