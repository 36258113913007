const styles = {
  scroll: {
    height: '75vh',
    overflowY: 'auto',
    '&::-webkit-scrollbar': { display: 'none' },
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
  },
  linear: {
    flex: 1,
    mr: 1,
  },
};

export default styles;
