const styles = {
  card: {
    marginTop: '1em',
  },
  semiCard: {
    width: '100%',
  },
  button: {
    margin: '1em 0',
  },
  image: {
    objectFit: 'contain',
    maxHeight: '150px',
    overflow: 'hidden',
    width: '100%',
  },
  detailsProps: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textDetailsProps: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  gridContainer: {
    height: '60vh',
  },
  grid: {
    '& .MuiDataGrid-virtualScroller': {
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  },
};

export default styles;
