const styles = {
  lotesPaper: {
    backgroundColor: '#f4f4f4',
    flex: 1,
  },
  lotesBtnBase: {
    width: '100%',
    padding: 1,
    borderRadius: '4px',
  },
  lotesCheckBox: {
    padding: 0,
    mx: 1,
  },
  icon: {
    ml: 1,
    color: 'black',
  },
  iconRed: {
    ml: 1,
    color: 'red',
  },
  itemPaper: {
    mx: 2,
    padding: 1,
    backgroundColor: '#f4f4f4',
  },
  tooltip: {
    whiteSpace: 'pre-line',
  },
  embPaper: {
    mx: 2,
    padding: 1,
    backgroundColor: '#22267B' + 14,
  },
};

export default styles;
