import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { Delete, Edit, QueryStats, Visibility } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toastWarning } from 'utils/toast';
import { checkInfosProduto, findOnArray, summarizer } from 'utils/functions';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { DropsContext } from 'contexts/DropsContext';
import { AuthContext } from 'contexts/AuthContext';
import { useDialog } from 'components/Modals';
import CatalogoModal from 'components/Modals/CatalogoModal';
import VirtualDrop from 'components/VirtualDrop';
import ButtonMenu from 'components/ButtonMenu';
import InputMask from 'components/InputMask';
import Container from 'components/Container';
import Dropdown from 'components/Dropdown';
import Button from 'components/Button';
import Loader from 'components/Loader';
import Header from 'components/Header';
import Input from 'components/Input';
import Card from 'components/Card';
import Blank from 'assets/blank.png';
import localeText from 'utils/localeText';
import ItemModal from './Modals/ItemModal';
import styles from './styles';
import moment from 'moment';

const Gerar = () => {
  const gridRef = useRef(null);
  const { documento_id, selected_itens } = useParams();
  const { user } = useContext(AuthContext);
  const { drops } = useContext(DropsContext);
  const { postDocumento, postLoading, getDocumento, getLoading, documento } =
    useContext(DocumentosContext);
  const defaultValues = {
    documento: '',
    dtemissao: moment().format('YYYY-MM-DD HH:mm:ss'),
    filial_id: user?.filial_id,
    cadastro_id: null,
    observacao: '',
    dadosadc: '',
    //ITENS
    produto_id: null,
    descricao: '',
    quantidade: '',
    unidade_medida_id: null,
    referencia: '',
    desconto1_pc: '',
    desconto_vlr: '',
    preco: '',
    przent: null,
    infadc: '',
    itens: [],
  };
  const navigate = useNavigate();
  const [additionals, setAdditionals] = useState(null);
  const [loading, setLoading] = useState(false);
  const { openDialog, closeDialog } = useDialog();
  const { control, handleSubmit, resetField, watch, setValue, reset } = useForm(
    { defaultValues }
  );

  const renderValues = () => {
    const filtered = JSON.parse(selected_itens || '[]');
    const itens = documento?.DocumentoItems?.filter((f) => {
      if (
        Array.isArray(filtered) &&
        Boolean(filtered?.length) &&
        !filtered?.some((s) => s === f?.id)
      ) {
        return false;
      } else {
        return true;
      }
    })
      ?.map((item) => {
        const Estruturas = item?.DocumentoItemEstruturas?.map((e) => {
          delete e?.Produto;
          delete e?.documento_item_id;
          delete e?.id;
          return e;
        });
        const Processos = item?.DocumentoItemProcessos?.map((p) => {
          delete p?.DocumentoItemProcessoAponts;
          delete p?.documento_item_id;
          delete p?.id;
          return p;
        });
        const Aprovacoes = item?.DocumentoItemAprovacaos?.map((a) => {
          delete a?.documento_item_id;
          delete a?.id;
          return a;
        });
        if (Boolean(Estruturas?.length)) item['Estruturas'] = Estruturas;
        if (Boolean(Processos?.length)) item['Processos'] = Processos;
        if (Boolean(Aprovacoes?.length)) item['Aprovacoes'] = Aprovacoes;
        const gerados = item?.Destinos?.filter(
          (d) => d?.especie_id === 2 && d?.natureza_operacao_id === 1
        );
        const quantidade = item?.quantidade - summarizer(gerados, 'quantidade');
        let preco = item?.custo || item?.preco;
        item['quantidade_max'] = quantidade;
        item['quantidade'] = quantidade;
        item['preco_max'] = preco;
        item['preco'] = preco;
        item['Origens'] = [{ documento_item_org_id: item?.id }];
        item['subtotal'] =
          (item?.quantidade || 0) * (preco || 0) + (item?.desconto_vlr || 0);
        delete item?.Cemovimentos;
        delete item?.Cfo;
        delete item?.Destinos;
        delete item?.DocumentoItemAprovacaos;
        delete item?.DocumentoItemEstruturas;
        delete item?.DocumentoItemImpostos;
        delete item?.DocumentoItemMc;
        delete item?.DocumentoItemOrigems;
        delete item?.DocumentoItemProcessos;
        delete item?.DocumentoItemQualidades;
        delete item?.DocumentoItemVariacaos;
        delete item?.Kit;
        delete item?.Produto;
        delete item?.UnidadeMedida;
        delete item?.Vinculados;
        delete item?.Vinculos;
        delete item?.cfo_id;
        delete item?.documento_id;
        delete item?.id;
        return item;
      })
      ?.filter((f) => Boolean(f?.quantidade));
    setValue('filial_id', documento?.filial_id || null);
    setValue('cadastro_id', documento?.cadastro_id || null);
    setValue('observacao', documento?.observacao || '');
    setValue('dadosadc', documento?.dadosadc || '');
    setValue('itens', itens);
  };

  useEffect(() => {
    if (Boolean(documento_id)) {
      getDocumento(documento_id);
    }
  }, []);

  useEffect(() => {
    if (Boolean(documento_id) && Boolean(documento)) {
      renderValues();
    }
  }, [documento]);

  useEffect(() => {
    const desconto_vlr =
      watch('desconto1_pc') * watch('preco') * watch('quantidade');
    setValue(
      'desconto_vlr',
      desconto_vlr !== 0 ? desconto_vlr * -0.01 : desconto_vlr
    );
  }, [watch('desconto1_pc'), watch('preco'), watch('quantidade')]);

  const subtotal =
    (watch('quantidade') || 0) * (watch('preco') || 0) +
    (watch('desconto_vlr') || 0);

  const getAdditionals = async (produto_id) => {
    setLoading(true);
    const produto = await checkInfosProduto({
      produto_id,
      disponivel: 'SIM',
      filial_id: watch('filial_id'),
      cadastro_id: watch('cadastro_id'),
    });
    setAdditionals(produto);
    setValue('descricao', produto?.descricao);
    setValue('quantidade', produto?.quantidade || 1);
    setValue('unidade_medida_id', produto?.unidade_medida_id);
    setValue('referencia', produto?.referencia);
    setValue('preco', produto?.custo);
    setLoading(false);
  };

  const onAddItem = (values) => {
    const subtotal =
      (values?.quantidade || 0) * (values?.preco || 0) +
      (values?.desconto_vlr || 0);
    setValue('itens', [
      ...values?.itens,
      {
        subtotal,
        produto_id: values?.produto_id,
        descricao: values?.descricao,
        quantidade: values?.quantidade,
        unidade_medida_id: values?.unidade_medida_id,
        referencia: values?.referencia,
        desconto1_pc: values?.desconto1_pc,
        desconto_vlr: values?.desconto_vlr,
        preco: values?.preco,
        przent: values?.przent,
        infadc: values?.infadc,
      },
    ]);
    setAdditionals(null);
    resetField('produto_id');
    resetField('descricao');
    resetField('quantidade');
    resetField('unidade_medida_id');
    resetField('referencia');
    resetField('desconto1_pc');
    resetField('desconto_vlr');
    resetField('preco');
    resetField('przent');
    resetField('infadc');

    if (gridRef?.current) {
      const virtualScroller = gridRef?.current?.querySelector(
        '.MuiDataGrid-virtualScroller'
      );
      if (virtualScroller) {
        setTimeout(() => {
          virtualScroller?.scrollTo({
            top: virtualScroller?.scrollHeight,
            behavior: 'smooth',
          });
        }, 500);
      }
    }
  };

  const onAddItemModal = (v) => {
    const data = v?.map((i) => ({
      produto_id: i?.id,
      descricao: i?.descricao,
      quantidade: i?.quantidade || 1,
      unidade_medida_id: i?.unidade_medida_id,
      referencia: i?.referencia,
      infadc: i?.infadc,
      przent: i?.przent,
      preco: i?.custo || i?.preco || 0,
      desconto1_pc: i?.desconto1_pc || 0,
      desconto_vlr: i?.desconto_vlr || 0,
      subtotal: (i?.custo || i?.preco || 0) * (i?.quantidade || 1),
    }));
    setValue('itens', [...watch('itens'), ...data]);

    if (gridRef?.current) {
      const virtualScroller = gridRef?.current?.querySelector(
        '.MuiDataGrid-virtualScroller'
      );
      if (virtualScroller) {
        setTimeout(() => {
          virtualScroller?.scrollTo({
            top: virtualScroller?.scrollHeight,
            behavior: 'smooth',
          });
        }, 500);
      }
    }
  };

  const onEditItem = ({ values, index }) => {
    const itens = watch('itens');
    const item = itens[index];
    if (values?.quantidade > item?.quantidade_max) {
      return toastWarning(
        `Quantidade limite excedida. (Limite: ${item?.quantidade_max})`
      );
    }
    if (Boolean(item?.preco_max) && values?.preco > item?.preco_max) {
      return toastWarning(
        `Preço limite excedido. (Limite: ${item?.preco_max})`
      );
    }
    const subtotal =
      (values?.quantidade || 0) * (values?.preco || 0) +
      (values?.desconto_vlr || 0);
    setValue(
      'itens',
      itens?.map((itm, i) =>
        index === i ? { ...itm, ...values, subtotal } : itm
      )
    );
    closeDialog();
  };

  const onDeleteItem = (index) => {
    setValue(
      'itens',
      watch('itens')?.filter((_, i) => i !== index)
    );
  };

  const onSubmit = (values) => {
    const data = {
      Documento: {
        especie_id: 2,
        natureza_operacao_id: 1,
        users_id: user?.id,
        filial_id: values?.filial_id,
        cadastro_id: values?.cadastro_id,
        dtemissao: values?.dtemissao,
        documento: values?.documento,
        observacao: values?.observacao,
        dadosadc: values?.dadosadc,
      },
      Entrada: {
        dtentrada: values?.dtemissao,
      },
      RequisicaoCompra: { users_id: user?.id },
      Itens: values?.itens?.map((i, index) => ({
        ...i,
        sequencia: index + 1,
      })),
    };
    if (Boolean(documento_id) && Boolean(documento?.especie_id === 16)) {
      data['Origens'] = [{ documento_id, status_id: 21 }];
    }
    const cb = () => {
      navigate(-1);
      reset();
    };
    postDocumento({ data, cb });
  };

  const options = [
    {
      name: 'Catálogo de Produtos',
      icon: 'balance',
      action: () =>
        openDialog(
          <CatalogoModal
            onSubmit={onAddItemModal}
            cadastro_id={watch('cadastro_id')}
          />,
          'Catálogo de Produtos'
        ),
    },
  ];

  if (getLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <Header titulo="Gerar Requisição de Compra" />
      <Card title="Informações Gerais">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Input
              name="documento"
              control={control}
              label="Nº do Documento"
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputMask
              name="dtemissao"
              control={control}
              label="Data de Emissão"
              type="datetime"
            />
          </Grid>
          <Grid item xs={12}>
            <Dropdown
              name="filial_id"
              control={control}
              label="Filial"
              options={drops?.Filial}
            />
          </Grid>
          <Grid item xs={10}>
            <VirtualDrop
              name="cadastro_id"
              control={control}
              label="Entidade"
              options={drops?.Entidade}
              refresh="Entidade"
            />
          </Grid>
          <Grid
            item
            xs={2}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <IconButton
              color="primary"
              size="small"
              onClick={() =>
                window.open(
                  `https://cadastros.eprom2.com.br/app/Entidades/Detalhes/${watch(
                    'cadastro_id'
                  )}`
                )
              }
              disabled={!Boolean(watch('cadastro_id'))}
            >
              <Visibility fontSize="small" />
            </IconButton>
            <IconButton
              color="primary"
              size="small"
              onClick={() =>
                window.open(
                  `https://cadastros.eprom2.com.br/app/Entidades/Indicadores/${watch(
                    'cadastro_id'
                  )}`
                )
              }
              disabled={!Boolean(watch('cadastro_id'))}
            >
              <QueryStats fontSize="small" />
            </IconButton>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              name="observacao"
              control={control}
              label="Observação"
              multiline
              rows={5}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              name="dadosadc"
              control={control}
              label="Dados Adicionais"
              multiline
              rows={5}
            />
          </Grid>
        </Grid>
      </Card>
      <Card title="Itens" style={styles?.card}>
        <Grid container spacing={2}>
          <Grid item xs={12} textAlign="center">
            <ButtonMenu
              buttonProps={{ variant: 'outlined' }}
              options={options}
            />
          </Grid>
          <Grid item xs={12} sm={6} display="flex">
            <Card style={styles?.semiCard}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <VirtualDrop
                    name="produto_id"
                    control={control}
                    label="Produto"
                    options={drops?.Produto}
                    onValueChange={(v) =>
                      Boolean(v) && getAdditionals(v?.value)
                    }
                    refresh="Produto"
                    busca_refinada
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input name="descricao" control={control} label="Descrição" />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <InputMask
                    name="quantidade"
                    control={control}
                    label="Quantidade"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Dropdown
                    name="unidade_medida_id"
                    control={control}
                    label="Unidade de Medida"
                    options={drops?.UnidadeMedida}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Input
                    name="referencia"
                    control={control}
                    label="Referência"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <InputMask
                    name="desconto1_pc"
                    control={control}
                    label="% Desconto"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <InputMask name="preco" control={control} label="Preço" />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <InputMask
                    name="przent"
                    control={control}
                    label="Prazo de Entrega"
                    type="date"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input
                    name="infadc"
                    control={control}
                    label="Informações Adicionais"
                  />
                </Grid>
                <Grid item xs={12} sm={6} {...styles?.detailsProps}>
                  <Box
                    component="img"
                    sx={styles?.image}
                    src={additionals?.imagem || Blank}
                  />
                </Grid>
                {Boolean(loading) ? (
                  <Grid item xs={12} sm={6} {...styles?.detailsProps}>
                    <Loader />
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={6} {...styles?.textDetailsProps}>
                    <Typography variant="h6" color="primary">
                      {additionals?.descricao}
                    </Typography>
                    <Typography variant="body1">
                      Referência: {additionals?.referencia || '-'}
                    </Typography>
                    <Typography variant="body1">
                      Saldo: {additionals?.saldo || '0'}{' '}
                      {additionals?.UnidadeMedida?.descricao || ''}
                    </Typography>
                    <Typography variant="body1">
                      Localização: {additionals?.localizador || '-'}
                    </Typography>
                    <Typography variant="h6" align="right" color="primary">
                      {subtotal?.toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={12} textAlign="center">
                  <Button
                    variant="contained"
                    color="primary"
                    loading={loading}
                    onClick={handleSubmit(onAddItem)}
                  >
                    ADICIONAR
                  </Button>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} display="flex">
            <Card style={styles?.semiCard}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box sx={styles?.gridContainer} ref={gridRef}>
                    <DataGrid
                      sx={styles?.grid}
                      rows={(watch('itens') || [])?.map((m, index) => ({
                        ...m,
                        index,
                      }))}
                      columns={[
                        {
                          field: 'referencia',
                          headerName: 'Referência',
                          sortable: false,
                          width: 100,
                        },
                        {
                          field: 'descricao',
                          headerName: 'Descrição',
                          sortable: false,
                          width: 300,
                        },
                        {
                          field: 'quantidade',
                          headerName: 'Quantidade',
                          type: 'number',
                          sortable: false,
                          width: 100,
                          valueGetter: ({ row }) => {
                            const um = findOnArray(
                              row?.unidade_medida_id,
                              drops?.UnidadeMedida,
                              'label'
                            );
                            return `${row?.quantidade || 0} ${um || ''}`;
                          },
                        },
                        {
                          field: 'desconto_vlr',
                          headerName: 'Desconto',
                          type: 'number',
                          sortable: false,
                          width: 100,
                          valueFormatter: ({ value }) =>
                            value?.toLocaleString('pt-br', {
                              style: 'currency',
                              currency: 'BRL',
                            }),
                        },
                        {
                          field: 'preco',
                          headerName: 'Preço Unitário',
                          type: 'number',
                          sortable: false,
                          width: 100,
                          valueFormatter: ({ value }) =>
                            value?.toLocaleString('pt-br', {
                              style: 'currency',
                              currency: 'BRL',
                            }),
                        },
                        {
                          field: 'subtotal',
                          headerName: 'Sub Total',
                          type: 'number',
                          sortable: false,
                          width: 100,
                          valueFormatter: ({ value }) =>
                            value?.toLocaleString('pt-br', {
                              style: 'currency',
                              currency: 'BRL',
                            }),
                        },
                        {
                          field: 'actions',
                          headerName: 'Ações',
                          type: 'actions',
                          width: 100,
                          getActions: ({ row }) => [
                            <GridActionsCellItem
                              size="small"
                              icon={<Edit />}
                              label="Editar"
                              onClick={() =>
                                openDialog(
                                  <ItemModal
                                    item={row}
                                    onSubmit={(values) =>
                                      onEditItem({
                                        values,
                                        index: row?.index,
                                      })
                                    }
                                  />,
                                  'Editar Item'
                                )
                              }
                            />,
                            <GridActionsCellItem
                              size="small"
                              icon={<Delete />}
                              label="Excluir"
                              onClick={() => onDeleteItem(row?.index)}
                            />,
                          ],
                        },
                      ]}
                      getRowHeight={() => 'auto'}
                      getRowId={(row) => JSON?.stringify(row)}
                      hideFooter
                      density="compact"
                      disableSelectionOnClick
                      disableColumnMenu
                      showCellRightBorder
                      showColumnRightBorder
                      localeText={localeText}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Typography variant="h6" color="primary">
              Total:{' '}
              {summarizer(watch('itens'), 'subtotal')?.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              })}
            </Typography>
          </Grid>
        </Grid>
      </Card>
      <Box textAlign="center" sx={styles?.button}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSubmit)}
          loading={postLoading}
        >
          Salvar
        </Button>
      </Box>
    </Container>
  );
};

export default Gerar;
