const styles = {
  card: {
    flex: 1,
    textAlign: 'center',
  },
  scroll: {
    height: '70vh',
    overflowY: 'auto',
    '&::-webkit-scrollbar': { display: 'none' },
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
  },
  linear: {
    flex: 1,
    mr: 1,
  },
  deleteIcon: {
    fontSize: 18,
  },
  backgroundEmb: {
    backgroundColor: '#22267B' + 14,
  },
};

export default styles;
