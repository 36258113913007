const styles = {
  container: {
    display: 'flex',
    overflowX: 'auto',
    height: '80vh',
    padding: 2,
    gap: 2,
    '&::-webkit-scrollbar': { display: 'none' },
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
  },
  stepContainer: {
    minWidth: 300,
    maxWidth: 300,
    maxHeight: '100%',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    '&::-webkit-scrollbar': { display: 'none' },
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
  },
  header: {
    position: 'sticky',
    top: 0,
    backgroundColor: 'primary.main',
    zIndex: 1,
    padding: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  icon: {
    color: 'primary.white',
  },
};

export default styles;
