import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Box,
  Icon,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { status, encryptURL } from 'utils/functions';
import { AuthContext } from 'contexts/AuthContext';
import { ExpedicoesContext } from 'contexts/ExpedicoesContext';
import Container from 'components/Container';
import Header from 'components/Header';
import Loader from 'components/Loader';
import Lotes from './Steps/Lotes';
import Itens from './Steps/Itens';
import styles from './styles';

const renderComponent = (props) => {
  switch (props?.index) {
    case 0:
      return <Lotes {...props} />;
    case 1:
      return <Itens {...props} attribute="quantidade_separada" />;
    case 2:
      return <Itens {...props} attribute="quantidade_embalada" />;
    case 3:
      return <Itens {...props} attribute="quantidade_volumada" />;
    default:
      return null;
  }
};

const Stepper = ({
  titulo,
  icon,
  tooltip,
  actions,
  fetch,
  loading,
  setOptions,
  index,
}) => {
  const lastScrollTop = useRef(0);
  const [page, setPage] = useState(0);
  const [itens, setItens] = useState([]);
  const [selected, setSelected] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const cb = (res) => {
    setItens((prev) => (Boolean(page) ? [...prev, ...res] : res));
    setIsFetching(false);
    if (res?.length < 10) {
      setHasMore(false);
    }
  };

  useEffect(() => {
    if (!isFetching && hasMore) {
      setIsFetching(true);
      fetch({ page }, cb);
    }
  }, [page]);

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    const isScrollingDown = scrollTop > lastScrollTop.current;
    lastScrollTop.current = scrollTop;
    const diff = scrollHeight - scrollTop - clientHeight < 100;

    if (isScrollingDown && diff && !loading) {
      setPage((prev) => prev + 1);
    }
  };

  const handleSelect = (item) => {
    if (selected?.some((s) => s?.id === item?.id)) {
      setSelected((prev) => prev?.filter((f) => f?.id !== item?.id));
    } else {
      setSelected((prev) => [...prev, item]);
    }
  };

  const handleOptions = (e) =>
    setOptions({ actions, selected, anchorEl: e?.currentTarget });

  return (
    <Paper sx={styles?.stepContainer} elevation={3} onScroll={handleScroll}>
      <Box sx={styles?.header}>
        <Tooltip title={tooltip}>
          <Icon sx={styles?.icon}>{icon}</Icon>
        </Tooltip>
        <Typography flex={1} variant="h6" color="primary.white" ml={2}>
          {titulo}
        </Typography>
        {Boolean(actions?.length) && (
          <IconButton sx={styles?.icon} size="small" onClick={handleOptions}>
            <Icon>more_vert</Icon>
          </IconButton>
        )}
      </Box>
      {renderComponent({ itens, index, selected, handleSelect })}
      {Boolean(loading) && <Loader />}
    </Paper>
  );
};

const Expedicoes = () => {
  const navigate = useNavigate();
  const rotina = 'Expedicoes';
  const titulo = 'Expedições';
  const { user } = useContext(AuthContext);
  const {
    lotesLoading,
    getLotes,
    separacaoLoading,
    getSeparacao,
    embalagemLoading,
    getEmbalagem,
    volumeLoading,
    getVolume,
  } = useContext(ExpedicoesContext);
  const [options, setOptions] = useState(null);

  const steps = [
    {
      titulo: 'Lotes',
      visible: true,
      loading: lotesLoading,
      fetch: (params, cb) => getLotes({ params, cb }),
      ...status?.LOTES,
      actions: [
        {
          name: 'Separar',
          icon: status?.SEPARACAO?.icon,
          show: user?.configuracoes?.Expedicao?.separacao,
          action: (arr) =>
            navigate(`/app/Expedicoes/Separacao/${encryptURL(arr)}`),
        },
        {
          name: 'Embalar',
          icon: status?.EMBALAGEM?.icon,
          show: user?.configuracoes?.Expedicao?.embalagem,
          action: (arr) =>
            navigate(`/app/Expedicoes/Embalagem/${encryptURL(arr)}`),
        },
        {
          name: 'Volumar',
          icon: status?.VOLUME?.icon,
          show: user?.configuracoes?.Expedicao?.volume,
          action: (arr) =>
            navigate(`/app/Expedicoes/Volume/${encryptURL(arr)}`),
        },
      ],
    },
    {
      titulo: 'Separação',
      loading: separacaoLoading,
      visible: user?.configuracoes?.Expedicao?.separacao,
      fetch: (params, cb) => getSeparacao({ params, cb }),
      ...status?.SEPARACAO,
    },
    {
      titulo: 'Embalagem',
      loading: embalagemLoading,
      visible: user?.configuracoes?.Expedicao?.embalagem,
      fetch: (params, cb) => getEmbalagem({ params, cb }),
      ...status?.EMBALAGEM,
    },
    {
      titulo: 'Volume',
      loading: volumeLoading,
      visible: user?.configuracoes?.Expedicao?.volume,
      fetch: (params, cb) => getVolume({ params, cb }),
      ...status?.VOLUME,
    },
  ];

  const renderActions = () =>
    options?.actions?.map(
      ({ action, icon, name, show }, index) =>
        Boolean(show) && (
          <MenuItem
            key={index?.toString()}
            disabled={!options?.actions}
            onClick={() => {
              setOptions(null);
              if (action) action(options?.selected);
            }}
          >
            {icon && (
              <ListItemIcon>
                <Icon>{icon}</Icon>
              </ListItemIcon>
            )}
            {name}
          </MenuItem>
        )
    );

  return (
    <Container>
      <Header rotina={rotina} titulo={titulo} />
      <Box sx={styles?.container}>
        {steps
          ?.filter((f) => Boolean(f?.visible))
          ?.map((props, index) => (
            <Stepper
              {...props}
              index={index}
              setOptions={setOptions}
              key={index?.toString()}
            />
          ))}
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={options?.anchorEl}
        open={Boolean(options?.anchorEl)}
        onClose={() => setOptions(null)}
      >
        {renderActions()}
      </Menu>
    </Container>
  );
};

export default Expedicoes;
