import React, { useEffect, useState } from 'react';
import { Grid, Step, StepLabel, Stepper } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useForm } from 'react-hook-form';
import { toastWarning } from 'utils/toast';
import { useDialog } from 'components/Modals';
import InputMask from 'components/InputMask';
import Loader from 'components/Loader';
import Button from 'components/Button';
import Input from 'components/Input';
import api from 'services/api';
import moment from 'moment';

const HistoricoCompraModal = ({ cadastro_id, produto_id, onSubmit }) => {
  const defaultValues = {
    search: '',
    dtemissao: moment().startOf('year').format('YYYY-MM-DD'),
  };
  const { closeDialog } = useDialog();
  const { control, handleSubmit } = useForm({ defaultValues });
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState([]);
  const [fullSelected, setFullSelected] = useState([]);
  const [itens, setItens] = useState([]);
  const [step, setStep] = useState(0);

  useEffect(() => {
    handleSubmit(loadItens)();
  }, []);

  useEffect(() => {
    setFullSelected(selected?.map((s) => itens?.find((i) => i?.id === s)));
  }, [selected]);

  const loadItens = async (values) => {
    try {
      setLoading(true);
      setSelected([]);
      setStep(0);
      const { data } = await api.post(`/Suprimentos/Compra/Historico`, {
        produto_id,
        cadastro_id,
        ...values,
      });
      setItens(data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const onConfirm = () => {
    const data = fullSelected?.map((i) => ({
      produto_id: i?.produto_id,
      descricao: i?.descricao,
      quantidade: i?.quantidade,
      unidade_medida_id: i?.unidade_medida_id,
      desconto1_pc: i?.desconto1_pc,
      desconto_vlr: i?.desconto_vlr,
      preco: i?.preco,
      przent: i?.przent,
      infadc: i?.infadc,
      referencia: i?.referencia,
      subtotal: (i?.preco || 0) * (i?.quantidade || 0) + (i?.desconto_vlr || 0),
    }));
    onSubmit(data);
    closeDialog();
  };

  const onCellEditCommit = (e) => {
    const editedRows = fullSelected?.map((c) => {
      if (c?.id === e?.id) {
        const novo = { ...c, [e?.field]: e?.value };
        let desconto_vlr = novo?.desconto1_pc * novo?.preco * novo?.quantidade;
        desconto_vlr = desconto_vlr !== 0 ? desconto_vlr * -0.01 : desconto_vlr;
        return { ...novo, desconto_vlr };
      } else {
        return { ...c };
      }
    });
    setFullSelected(editedRows);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={8}>
        <Input name="search" control={control} label="Pesquisar" />
      </Grid>
      <Grid item xs={12} sm={4}>
        <InputMask
          name="dtemissao"
          control={control}
          type="date"
          label="Data da Compra"
        />
      </Grid>
      <Grid
        item
        xs={12}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Button
          variant="outlined"
          color="primary"
          loading={loading}
          onClick={handleSubmit(loadItens)}
        >
          Buscar
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Stepper activeStep={step} alternativeLabel>
          <Step>
            <StepLabel>Selecionar Compras</StepLabel>
          </Step>
          <Step>
            <StepLabel>Ajustar Valores</StepLabel>
          </Step>
        </Stepper>
      </Grid>
      {step === 0 && (
        <>
          <Grid item xs={12}>
            {loading ? (
              <Loader />
            ) : (
              <DataGrid
                rows={itens}
                columns={[
                  {
                    field: 'dtemissao',
                    headerName: 'Data de Emissão',
                    width: 200,
                    sortable: false,
                    valueGetter: ({ value }) =>
                      value && moment(value).format('DD/MM/YYYY'),
                  },
                  {
                    field: 'documento',
                    headerName: 'Documento',
                    width: 150,
                    sortable: false,
                  },
                  {
                    field: 'referencia',
                    headerName: 'Referência',
                    width: 150,
                    sortable: false,
                  },
                  {
                    field: Boolean(cadastro_id) ? 'descricao' : 'entidade',
                    headerName: Boolean(cadastro_id) ? 'Produto' : 'Fornecedor',
                    width: 400,
                    sortable: false,
                  },
                  {
                    field: 'quantidade',
                    headerName: 'Quantidade',
                    type: 'number',
                    width: 150,
                    sortable: false,
                    valueGetter: ({ row }) =>
                      row?.quantidade && row?.unidade_medida
                        ? `${row?.quantidade} ${row?.unidade_medida}`
                        : row?.quantidade || 0,
                  },
                  {
                    field: 'preco',
                    headerName: 'Preço Unitário',
                    type: 'number',
                    width: 150,
                    sortable: false,
                    valueGetter: ({ value }) =>
                      value &&
                      value?.toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                      }),
                  },
                  {
                    field: 'desconto1_pc',
                    headerName: '% Desconto',
                    type: 'number',
                    width: 150,
                    sortable: false,
                    valueGetter: ({ value }) => value && value + '%',
                  },
                  {
                    field: 'desconto_vlr',
                    headerName: 'Valor Desconto',
                    type: 'number',
                    width: 150,
                    sortable: false,
                    valueGetter: ({ value }) =>
                      value &&
                      value?.toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                      }),
                  },
                  {
                    field: 'subtotal',
                    headerName: 'Valor Total',
                    type: 'number',
                    width: 150,
                    sortable: false,
                    valueGetter: ({ row }) =>
                      (
                        (row?.preco || 0) * (row?.quantidade || 0) +
                        (row?.desconto_vlr || 0)
                      )?.toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                      }),
                  },
                  {
                    field: 'saldo',
                    headerName: 'Saldo',
                    type: 'number',
                    width: 150,
                    sortable: false,
                    valueGetter: ({ row }) =>
                      row?.saldo && row?.unidade_medida
                        ? `${row?.saldo} ${row?.unidade_medida}`
                        : row?.saldo || 0,
                  },
                ]}
                hideFooter
                autoHeight
                density="compact"
                disableSelectionOnClick
                disableColumnMenu
                showCellRightBorder
                showColumnRightBorder
                checkboxSelection
                localeText={{ noRowsLabel: 'Nenhum Registro.' }}
                selectionModel={selected}
                onSelectionModelChange={(ids) => setSelected(ids)}
              />
            )}
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                if (Boolean(selected?.length)) {
                  setStep(step + 1);
                } else {
                  toastWarning('Selecione ao menos uma compra');
                }
              }}
            >
              Próximo
            </Button>
          </Grid>
        </>
      )}
      {step === 1 && (
        <>
          <Grid item xs={12}>
            <DataGrid
              rows={fullSelected}
              columns={[
                {
                  field: Boolean(cadastro_id) ? 'descricao' : 'entidade',
                  headerName: Boolean(cadastro_id) ? 'Produto' : 'Cliente',
                  flex: 3,
                  sortable: false,
                },
                {
                  field: 'ult_desconto1_pc',
                  headerName: '% Desconto (Última Venda)',
                  type: 'number',
                  flex: 1,
                  sortable: false,
                  valueGetter: ({ value }) => value && value + '%',
                },
                {
                  field: 'ult_preco',
                  headerName: 'Preço Unitário (Última Venda)',
                  type: 'number',
                  flex: 1,
                  sortable: false,
                  valueGetter: ({ value }) =>
                    value &&
                    value?.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    }),
                },
                {
                  field: 'quantidade',
                  headerName: 'Quantidade',
                  type: 'number',
                  flex: 1,
                  sortable: false,
                  editable: true,
                  valueGetter: ({ row }) =>
                    row?.quantidade && row?.unidade_medida
                      ? `${row?.quantidade} ${row?.unidade_medida}`
                      : row?.quantidade || 0,
                },
                {
                  field: 'desconto1_pc',
                  headerName: '% Desconto',
                  type: 'number',
                  flex: 1,
                  sortable: false,
                  editable: true,
                },
                {
                  field: 'preco',
                  headerName: 'Preço Unitário',
                  type: 'number',
                  flex: 1,
                  sortable: false,
                  editable: true,
                  valueGetter: ({ value }) =>
                    value &&
                    value?.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    }),
                },
              ]}
              hideFooter
              autoHeight
              density="compact"
              disableSelectionOnClick
              disableColumnMenu
              showCellRightBorder
              showColumnRightBorder
              localeText={{ noRowsLabel: 'Nenhum Registro.' }}
              onCellEditCommit={onCellEditCommit}
            />
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => setStep(step - 1)}
              sx={{ mx: '.5em' }}
            >
              Voltar
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={onConfirm}
              sx={{ mx: '.5em' }}
            >
              Confirmar
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default HistoricoCompraModal;
