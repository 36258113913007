import {
  Box,
  ButtonBase,
  Checkbox,
  Icon,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { status } from 'utils/functions';
import styles from './styles';
import moment from 'moment';

const Line = ({ label, value }) => (
  <Box display="flex" alignItems="center" flex={1}>
    <Typography variant="caption" fontWeight="bold" flex={1} textAlign="start">
      {label}:
    </Typography>
    <Typography variant="caption" flex={2} textAlign="start">
      {value || '-'}
    </Typography>
  </Box>
);

const Lotes = ({ itens = [], selected, handleSelect }) => {
  const navigate = useNavigate();

  return itens?.map((item, idx) => (
    <Box key={idx} display="flex" alignItems="center" mr={2}>
      <Checkbox
        size="small"
        disableRipple
        sx={styles?.lotesCheckBox}
        disabled={item?.status === 'SEM SALDO'}
        checked={selected?.some((s) => s?.id === item?.id)}
        onChange={() => handleSelect(item)}
      />
      <Paper sx={styles?.lotesPaper} elevation={1}>
        <ButtonBase
          sx={styles?.lotesBtnBase}
          onClick={() => navigate(`/app/Expedicoes/Detalhes/${item?.id}`)}
        >
          <Box display="flex" flexDirection="column" flex={1}>
            <Typography variant="body1" fontWeight="bold" alignSelf="start">
              Lote {item?.documento || ''}
            </Typography>
            <Line
              label="Emissão"
              value={
                Boolean(item?.dtemissao) &&
                moment(item?.dtemissao)?.format('DD/MM/YYYY [às] HH:mm')
              }
            />
            <Line
              label="Entrega"
              value={
                Boolean(item?.przent) &&
                moment(item?.przent)?.format('DD/MM/YYYY')
              }
            />
          </Box>
          <Tooltip title={status[item?.status]?.tooltip}>
            <Icon
              sx={item?.status === 'SEM SALDO' ? styles?.iconRed : styles?.icon}
            >
              {status[item?.status]?.icon}
            </Icon>
          </Tooltip>
        </ButtonBase>
      </Paper>
    </Box>
  ));
};

export default Lotes;
