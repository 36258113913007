import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, Grid, LinearProgress, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { useForm } from 'react-hook-form';
import { decryptURL } from 'utils/functions';
import { AuthContext } from 'contexts/AuthContext';
import { ExpedicoesContext } from 'contexts/ExpedicoesContext';
import Container from 'components/Container';
import Dropdown from 'components/Dropdown';
import Header from 'components/Header';
import Input from 'components/Input';
import Card from 'components/Card';
import localeText from 'utils/localeText';
import styles from './styles';

const Separacao = () => {
  let timer;
  const waitTime = 1000;
  const { hash } = useParams();
  const lotes = decryptURL(hash);
  const enderecoRef = useRef(null);
  const produtoRef = useRef(null);
  const refs = useRef({});
  const defaultValues = {
    quantidade: 1,
    ceendereco_id: '',
    produto_id: '',
    pedido_id: null,
  };
  const [selected, setSelected] = useState(null);
  const [pedidos, setPedidos] = useState([]);
  const { getSeparacao, separacaoLoading, postSeparacao, postLoading } =
    useContext(ExpedicoesContext);
  const { user } = useContext(AuthContext);
  const { control, setValue, watch, handleSubmit } = useForm({ defaultValues });
  const _pedidos = pedidos?.filter((f) => {
    const pd = watch('pedido_id');
    return Boolean(pd) ? f?.id === pd : true;
  });
  const enderecamento = Boolean(user?.configuracoes?.Expedicao?.enderecamento);

  const fetchData = ({ data = [], item_id = null }) => {
    const agrupado = data?.reduce((acc, item) => {
      const existingPedido = acc.find((grupo) => grupo.id === item.pedido_id);

      if (existingPedido) {
        existingPedido.itens.push(item);
      } else {
        acc.push({
          id: item?.pedido_id,
          pedido: item?.pedido,
          cliente: item?.cliente,
          destino: item?.destino,
          itens: [item],
        });
      }

      return acc;
    }, []);

    if (agrupado?.length > 0) {
      refs.current = agrupado?.reduce((acc, _, index) => {
        acc[index] = React.createRef();
        return acc;
      }, {});
    }

    setPedidos(agrupado);
    setValue('quantidade', 1);
    setValue('produto_id', '');
    setValue('ceendereco_id', '');
    if (!agrupado?.some((s) => s?.id === watch('pedido_id'))) {
      setValue('pedido_id', null);
      setSelected(null);
    }

    if (Boolean(item_id)) {
      const index = agrupado
        ?.filter((f) => {
          const pd = watch('pedido_id');
          return Boolean(pd) ? f?.id === pd : true;
        })
        ?.findIndex((item) => item?.itens?.some((s) => s?.id === item_id));

      if (index !== -1 && refs.current[index]) {
        setTimeout(() => {
          setSelected(item_id);
          refs.current[index].current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }, 500);
      }
    }

    setTimeout(
      () =>
        enderecamento
          ? enderecoRef?.current?.focus()
          : produtoRef?.current?.focus(),
      500
    );
  };

  const loadData = (props) => {
    const item_id = props?.item_id || null;
    const params = { lotes: lotes?.map((l) => l?.id) };
    getSeparacao({ params, cb: (data) => fetchData({ data, item_id }) });
  };

  useEffect(() => {
    loadData();
  }, []);

  const onSubmit = (values) => {
    const data = {
      ...values,
      lotes: lotes?.map((l) => l?.id),
    };
    postSeparacao({ data, cb: (res) => loadData({ item_id: res?.id }) });
  };

  return (
    <Container>
      <Header titulo="Separação" />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={enderecamento ? 3 : 4}>
                <Dropdown
                  name="pedido_id"
                  control={control}
                  label="Pedido"
                  options={pedidos?.map((m) => ({
                    value: m?.id,
                    label: 'Pedido ' + m?.pedido,
                  }))}
                />
              </Grid>
              <Grid item xs={12} sm={enderecamento ? 3 : 4}>
                <Input
                  name="quantidade"
                  control={control}
                  label="Quantidade"
                  type="number"
                  onKeyUp={(e) => {
                    clearTimeout(timer);
                    timer = setTimeout(() => {
                      const value = e?.target?.value;
                      if (Boolean(value)) {
                        enderecamento
                          ? enderecoRef?.current?.focus()
                          : produtoRef?.current?.focus();
                      }
                    }, waitTime);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={3} display={!enderecamento && 'none'}>
                <Input
                  inputRef={enderecoRef}
                  name="ceendereco_id"
                  control={control}
                  label="Endereço de Estoque"
                  onKeyUp={(e) => {
                    clearTimeout(timer);
                    timer = setTimeout(() => {
                      const value = e?.target?.value;
                      if (Boolean(value)) {
                        produtoRef?.current?.focus();
                      }
                    }, waitTime);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={enderecamento ? 3 : 4}>
                <Input
                  inputRef={produtoRef}
                  name="produto_id"
                  control={control}
                  label="Produto"
                  onKeyUp={(e) => {
                    clearTimeout(timer);
                    timer = setTimeout(() => {
                      const value = e?.target?.value;
                      if (Boolean(value)) {
                        handleSubmit(onSubmit)();
                      }
                    }, waitTime);
                  }}
                />
              </Grid>
              <Grid item xs={12} sx={styles?.scroll} textAlign="center">
                {Boolean(_pedidos?.length) ? (
                  _pedidos?.map((item, idx) => (
                    <Box ref={refs.current[idx]} key={idx?.toString()} mt={2}>
                      <Typography variant="h6" textAlign="start">
                        Pedido {item?.pedido || ''}
                      </Typography>
                      {Boolean(item?.cliente) && (
                        <Typography fontSize={14} textAlign="start">
                          {item?.cliente}
                        </Typography>
                      )}
                      {Boolean(item?.destino) && (
                        <Typography fontSize={14} textAlign="start">
                          {item?.destino}
                        </Typography>
                      )}
                      <DataGrid
                        rows={item?.itens}
                        columns={[
                          {
                            field: 'referencia',
                            headerName: 'Referência',
                            width: 150,
                            sortable: false,
                          },
                          {
                            field: 'descricao',
                            headerName: 'Descrição',
                            flex: 1,
                            sortable: false,
                          },
                          {
                            field: 'localizador',
                            headerName: 'Localização',
                            width: 200,
                            sortable: false,
                          },
                          {
                            field: 'quantidade',
                            headerName: 'Quantidade',
                            width: 200,
                            type: 'number',
                            sortable: false,
                            valueGetter: ({ row }) =>
                              `${row?.quantidade_separada || 0} / ${
                                row?.quantidade || 0
                              } ${row?.um || ''}`,
                          },
                          {
                            field: 'progresso',
                            headerName: 'Progresso',
                            width: 200,
                            type: 'number',
                            sortable: false,
                            renderCell: ({ row }) => {
                              const progress = Math.round(
                                ((row?.quantidade_separada || 0) /
                                  (row?.quantidade || 0)) *
                                  100
                              );
                              return (
                                <Box
                                  flex={1}
                                  display="flex"
                                  alignItems="center"
                                >
                                  <LinearProgress
                                    variant="determinate"
                                    value={progress}
                                    sx={styles?.linear}
                                    color={
                                      progress < 100 ? 'warning' : 'success'
                                    }
                                  />
                                  <Typography variant="body2">
                                    {progress}%
                                  </Typography>
                                </Box>
                              );
                            },
                          },
                        ]}
                        hideFooter
                        autoHeight
                        density="compact"
                        loading={postLoading || separacaoLoading}
                        selectionModel={[selected]}
                        disableColumnMenu
                        showCellRightBorder
                        showColumnRightBorder
                        localeText={localeText}
                        components={{
                          LoadingOverlay: LinearProgress,
                        }}
                      />
                    </Box>
                  ))
                ) : separacaoLoading ? (
                  <LinearProgress />
                ) : (
                  <Typography variant="caption">
                    Nenhum item encontrado
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Separacao;
