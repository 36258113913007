import React, { useContext, useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Grid } from '@mui/material';
import { useDialog } from '..';
import { useForm } from 'react-hook-form';
import { ProdutosContext } from 'contexts/ProdutosContext';
import { DropsContext } from 'contexts/DropsContext';
import localeText from 'utils/localeText';
import Dropdown from 'components/Dropdown';
import Button from 'components/Button';

const EtiquetaModal = ({ itens }) => {
  const defaultValues = { impressora_id: null, modelo_id: null };
  const [etiquetas, setEtiquetas] = useState([]);
  const { closeDialog } = useDialog();
  const { drops } = useContext(DropsContext);
  const { postLoading, postEtiquetas } = useContext(ProdutosContext);
  const { control, handleSubmit, watch, resetField } = useForm({
    defaultValues,
  });

  useEffect(() => {
    setEtiquetas(
      itens
        .map((m) => {
          const quantidade_max =
            (m?.quantidade || 0) - (m?.quantidade_embalada || 0);
          return { ...m, quantidade: quantidade_max, quantidade_max };
        })
        ?.filter((f) => f?.quantidade_max > 0)
    );
  }, []);

  const onSubmit = (values) => {
    postEtiquetas({
      data: {
        ...values,
        produtos: etiquetas
          ?.filter((f) => Boolean(f?.quantidade))
          ?.map((e) => ({
            produto_id: e?.produto_id,
            quantidade: e?.quantidade,
          })),
      },
      cb: closeDialog,
    });
  };

  const onCellEditCommit = (e) => {
    const editedRows = etiquetas?.map((c) => {
      if (c?.id === e?.id) {
        if (e?.field === 'quantidade') {
          return {
            ...c,
            [e?.field]:
              e?.value > c?.quantidade_max ? c?.quantidade_max : e?.value,
          };
        } else {
          return { ...c, [e?.field]: e?.value };
        }
      } else {
        return { ...c };
      }
    });
    setEtiquetas(editedRows);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Dropdown
          name="impressora_id"
          control={control}
          label="Impressora"
          options={drops?.Impressoraweb}
          onValueChange={() => resetField('modelo_id')}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Dropdown
          name="modelo_id"
          control={control}
          label="Modelo"
          options={drops?.EtiquetaModelo?.filter(
            (f) => f?.impressoraweb_id === watch('impressora_id')
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <DataGrid
          rows={etiquetas}
          columns={[
            {
              field: 'referencia',
              headerName: 'Referência',
              width: 150,
              sortable: false,
            },
            {
              field: 'descricao',
              headerName: 'Produto',
              flex: 1,
              sortable: false,
            },
            {
              field: 'quantidade',
              headerName: 'Quantidade',
              type: 'number',
              width: 200,
              editable: true,
              sortable: false,
            },
          ]}
          hideFooter
          autoHeight
          density="compact"
          disableSelectionOnClick
          disableColumnMenu
          showCellRightBorder
          showColumnRightBorder
          localeText={localeText}
          onCellEditCommit={onCellEditCommit}
        />
      </Grid>
      <Grid item xs={12} textAlign="center">
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSubmit)}
          loading={postLoading}
        >
          Imprimir
        </Button>
      </Grid>
    </Grid>
  );
};

export default EtiquetaModal;
