const styles = {
  modalChip: {
    mt: 1,
  },
  card: {
    flex: 1,
    textAlign: 'center',
  },
  btn: {
    alignSelf: 'center',
  },
  btnft: {
    mb: 2,
  },
  checkbox: {
    padding: 0,
    mr: 1,
  },
};

export default styles;
